var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "models-page" },
    [
      _c(
        "mega-header",
        {
          staticClass: "overlay-dark overlay-90 text-lighter",
          attrs: {
            title: _vm.$t("arr_manager"),
            background:
              "https://images.pexels.com/photos/1902647/pexels-photo-1902647.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          }
        },
        [
          _c("span", { staticClass: "text-muted" }, [
            _vm._v(
              _vm._s(_vm.$t("welcome")) + " " + _vm._s(_vm.user.email) + "!"
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("section", [
        _vm.list.length > 0
          ? _c("div", { staticClass: "container py-5" }, [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.list, function(arp, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass:
                        "xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
                    },
                    [
                      _c("mega-card", { staticClass: "rounded h-100 mb-0" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "navbar bg-white border-bottom rounded-top"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "container-fluid py-3" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "w-100 navbar-label mr-auto text-dark",
                                    attrs: {
                                      to: {
                                        name: "Arr",
                                        params: { arr_id: arp.idt_arp }
                                      }
                                    }
                                  },
                                  [
                                    _c("mega-image", {
                                      staticClass:
                                        "rounded-full navbar-avatar mr-3",
                                      attrs: { small: "", src: arp.logo }
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "content" }, [
                                      _c("small", { staticClass: "strong" }, [
                                        _vm._v(_vm._s(arp.name) + " ")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        { staticClass: "text-muted" },
                                        [_vm._v(_vm._s("@arr_" + arp.idt_arp))]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "d-flex text-center w-100 justify-content-center align-items-center mt-4"
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.$t("arr_absence")) + "\n        "
            )
          ]
        ),
        _vm._v(" "),
        !_vm.loaded
          ? _c("div", { staticClass: "container my-5 text-center" }, [
              !_vm.loaded
                ? _c(
                    "span",
                    {
                      staticClass:
                        "btn btn-info opacity-50 px-6 loading sm-down:d-none"
                    },
                    [_vm._v("Loading")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "btn btn-info px-6 md-up:d-none",
                  attrs: { disabled: !_vm.loaded },
                  on: {
                    click: function($event) {
                      _vm.page < Math.ceil(_vm.length / _vm.loadCount)
                        ? _vm.page++
                        : ""
                    }
                  }
                },
                [_vm._v("Load more")]
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }