<template>
    <main class="models-page">

        <mega-header class="overlay-dark overlay-90 text-lighter"
                     :title="$t('arr_manager')"
                     background="https://images.pexels.com/photos/1902647/pexels-photo-1902647.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260">
            <span class="text-muted">{{ $t('welcome') }} {{user.email}}!</span>
        </mega-header>

        <section>
            <div class="container py-5" v-if="list.length > 0">
                <div class="row">
                    <div class="xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
                         v-for="(arp, i) in list"
                         :key="i">
                        <mega-card class="rounded h-100 mb-0">

                            <div class="navbar bg-white border-bottom rounded-top">

                                <div class="container-fluid py-3">

                                    <router-link :to="{name: 'Arr', params: {arr_id: arp.idt_arp}}" class="w-100 navbar-label mr-auto text-dark">

                                        <mega-image class="rounded-full navbar-avatar mr-3" small :src="arp.logo"/>

                                        <div class="content">
                                            <small class="strong">{{arp.name}} </small>
                                            <small class="text-muted">{{'@arr_'+arp.idt_arp}}</small>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </mega-card>
                    </div>
                </div>
            </div>

            <div class="d-flex text-center w-100 justify-content-center align-items-center mt-4">
                {{ $t('arr_absence') }}
            </div>

            <div class="container my-5 text-center" v-if="!loaded">

                <span class="btn btn-info opacity-50 px-6 loading sm-down:d-none"
                      v-if="!loaded">Loading</span>

                <span class="btn btn-info px-6 md-up:d-none"
                      :disabled="!loaded"
                      @click="page < Math.ceil(length / loadCount) ? page++ : ''">Load more</span>
            </div>
        </section>
    </main>
</template>

<script>

    export default {
        name: 'ARR_List',
        data() {
            return {
                list: [],
                selected: [],
                loadCount: 20,
                page: 0,
                length: 0,
                loaded: false,
                showModal: false,
                multiple: false
            }
        },
        computed: {
            user() {
                return this.$store.state.user;
            }
        },
        mounted() {
            this.$navbar.name = this.$t('arr_mine');

            window.addEventListener('scroll', this.checkScroll);
            window.addEventListener('resize', this.checkScroll);

            this.loadPage();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.checkScroll);
            window.removeEventListener('resize', this.checkScroll);
        },
        methods: {

            checkScroll() {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight - 300;
                if (this.loaded && bottomOfWindow && this.page < Math.ceil(this.length / this.loadCount)) {
                    this.page++;
                    this.loadPage();
                }
            },

            loadPage() {
                this.loaded = false;

                let params = {};

                this.$api.v2.get('/arp/my', {params})
                    .then(response => {
                        this.length = response.data.count;
                        this.loaded = true;

                        this.$root.page = 'My ARR ' + '('+this.length+')';

                        response.data.arps.map(item => this.list.push(item));
                    })
                    .then(() => {
                        if (this.list[0].idt_arp) {
                            this.$router.push({ name: 'Arr', params: { arr_id: this.list[0].idt_arp } });
                        } else {
                            this.$router.push({ name: 'Arr_List' })
                        }
                    })
                    .catch(() => {
                    this.loaded = true;
                })
            }
        }
    }
</script>
